.menu--mohe {
  display: grid;
  grid-template-rows: repeat(3, 3rem);
  .menu__item {
    //  font-family: "PontanoSans-Regular", sans-serif;
    //  font-weight: 700;
    display: grid;
    justify-content: left;
    margin: unset;
    text-transform: lowercase;
    grid-template-columns: auto;
    max-width: 10rem;
    &:hover {
      .menu__item-label {
        color: $color-white;
        opacity: 1;
      }
    }

    .menu__item-name {
      // font-size: 1.3rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      white-space: pre;
      pointer-events: none;
      color: $color-white;
      grid-area: 1 / 1 / 2 / 2;
    }

    .menu__item-label {
      // font-size: 1.3rem;
      line-height: 1.4;
      overflow: hidden;
      margin: 0;
      text-align: center;
      color: transparent;
      grid-area: 1 / 1 / 2 / 2;
      opacity: 0;
      transition: all 0.5s 0.3s;
    }

    .menu__item:hover {
      .menu__item-label {
        color: black;
      }
    }
    .menu__item:focus {
      .menu__item-label {
        color: black;
      }
    }

    .menu--mohe .menu__item-label::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: blue;
      color: black;
      transform: translate3d(-100%, 0, 0) translate3d(-1px, 0, 0);
      transition: all 0.6s;
      transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
    }
  }
}

.graphic--nao {
  stroke-dashoffset: 0px !important;
}

//            Checkbox Style Starts

.MuiCheckbox-colorSecondary.Mui-checked {
  color: $color-primary-dark !important;
}

.MuiSvgIcon-root {
  fill: $color-primary-dark !important;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  /* transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
  flex-shrink: 0;
  user-select: none;
}

//  ----   Loader Effect Starts    --------    //

.b-effects-loader {
  display: grid;
  width: 100%;
  height: 100vh;
  padding-top: 45vh;

  .loader-inner {
    min-width: 50vw;
    margin-left: auto;
    margin-right: auto;
  }

  .ball-pulse > div:nth-child(1) {
    -webkit-animation: scale 1s -0.24s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
    animation: scale 1s -0.24s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  }

  .ball-pulse > div:nth-child(2) {
    -webkit-animation: scale 1s -0.12s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
    animation: scale 1s -0.12s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  }

  .ball-pulse > div:nth-child(3) {
    -webkit-animation: scale 1s 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
    animation: scale 1s 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  }

  .ball-pulse > div {
    background-color: $color-tertiary-dark;
    width: 10vw;
    height: 10vw;
    border-radius: 100%;
    margin: 5vw;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    display: inline-block;
  }

  @-webkit-keyframes scale {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
    45% {
      -webkit-transform: scale(0.1);
      transform: scale(0.1);
      opacity: 0.7;
    }
    80% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
  }

  @keyframes scale {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
    45% {
      -webkit-transform: scale(0.1);
      transform: scale(0.1);
      opacity: 0.2;
    }
    80% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
  }
}

//  ----   Loader Effect Ends      --------    //

//  ----   ToolTip Effect Starts      --------    //

.__react_component_tooltip {
  border-radius: 3px;
  background-color: #dbb59d;
  display: inline-block;
  font-size: 13px;
  left: -999em;
  opacity: 0;
  padding: 8px 21px;
  position: fixed;
  pointer-events: none;
  transition: opacity 0.3s ease-out;
  top: -999em;
  visibility: hidden;
  z-index: 999;
}
.__react_component_tooltip.allow_hover,
.__react_component_tooltip.allow_click {
  pointer-events: auto;
}
.__react_component_tooltip::before,
.__react_component_tooltip::after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
}
.__react_component_tooltip.show {
  opacity: 0.9;
  margin-top: 0;
  margin-left: 0;
  visibility: visible;
}
.__react_component_tooltip.place-top::before {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  bottom: -8px;
  left: 50%;
  margin-left: -10px;
}
.__react_component_tooltip.place-bottom::before {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  top: -8px;
  left: 50%;
  margin-left: -10px;
}
.__react_component_tooltip.place-left::before {
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  right: -8px;
  top: 50%;
  margin-top: -5px;
}
.__react_component_tooltip.place-right::before {
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  left: -8px;
  top: 50%;
  margin-top: -5px;
}
.__react_component_tooltip .multi-line {
  display: block;
  padding: 2px 0;
  text-align: center;
}

//  ----   ToolTip Effect Ends      --------    //

//      Mobile Styles Starts Here
@media (max-width: 765px) and (min-width: 300px) {
  .menu--mohe .menu__item .menu__item-name {
    font-size: 16px;
  }
}
//      Mobile Styles Ends Here
