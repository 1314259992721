.l-lastest {
  background-color: $color-secondary-light;
  display: block;
  width: 100%;
  padding-top: 15vh;
  padding-bottom: 5%;
  position: relative;
  animation: arriveRight 2s ease-in-out;

  h1 {
    align-self: center;
    text-align: center;
    grid-row-start: 1;
    text-transform: capitalize;
    color: $color-primary-dark;
    animation: arriveRight 3s ease-in-out;
  }
  &__content {
    display: grid;
    grid-template-columns: 30rem 1fr 30rem;
    animation: arriveRight 3s ease-in-out 1s;

    p {
      color: $color-primary-dark;
      text-align: center;
      font-size: 1.7rem;
      grid-column-start: 2;
    }

    img:nth-child(1) {
      width: 70rem;
      object-fit: cover;
      grid-column-start: 2;
      margin-top: 10rem;
      justify-self: center;
      position: relative;
    }

    img:nth-child(2) {
      width: 70rem;
      object-fit: cover;
      grid-column-start: 2;
      margin-top: 10rem;
      justify-self: center;
      position: relative;
    }

    img:nth-child(3) {
      width: 70rem;
      grid-column-start: 2;
      margin-top: 10rem;
      justify-self: start;
      position: relative;
    }

    img:nth-child(4) {
      width: 50rem;
      grid-column-start: 2;
      margin-top: 10rem;
      justify-self: end;
      position: relative;
    }

    img:nth-child(5) {
      width: 70rem;
      grid-column-start: 2;
      margin-top: 10rem;
      justify-self: start;
      position: relative;
    }

    img:nth-child(6) {
      width: 70rem;
      grid-column-start: 2;
      margin-top: 10rem;
      justify-self: end;
      position: relative;
    }

    img:nth-child(7) {
      width: 70rem;
      grid-column-start: 2;
      margin-top: 10rem;
      justify-self: start;
      position: relative;
    }

    img:nth-child(8) {
      width: 70rem;
      object-fit: contain;
      grid-column-start: 2;
      margin-top: 10rem;
      justify-self: center;
      position: relative;
    }
  }

  //      Ipad & Tablet Styles Starts Here
  @media (max-width: 1200px) and (min-width: 767px) {
    &__content {
      display: grid;
      grid-template-columns: 5% 1fr 5%;
    }
  }
  //      Ipad & Tablet Styles Ends Here

  //      Mobile Styles Starts Here
  @media (max-width: 765px) and (min-width: 300px) {
    &__content {
      grid-template-columns: 5vw 90vw 5vw;

      img {
        width: 95vw !important;
      }
    }
  }
  //      Mobile Styles Ends Here
}
