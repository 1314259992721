// Here Starts the style for the light button

.f-bs {
  min-width: 300px;
  max-width: 300px;
  height: 40px;
  background-color: unset;
  color: #ae97a2;
  font-weight: bold;
  font-size: 16px;
  position: relative;
  margin-top: 0px;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  line-height: 0px;
  letter-spacing: 0.1 0.5px;
  text-transform: uppercase;
  outline: 0 !important;
  border: none !important;
  outline: none !important;

  .f-bs-arrow {
    top: -5px;
    width: 17px;
    height: 20px;
    fill: #ae97a2;
    position: absolute;
    opacity: 1;
    transform: translate(-58.3296px, 0px);
    transition: all 0.5s ease-in-out;

    &:nth-child(1) {
      opacity: 1;
      left: 100px;
      transition: all 0.5s ease-in-out;
    }
    &:nth-child(2) {
      opacity: 0;
      right: 20px;
      transition: all 0.5s ease-in-out;
    }
  }

  .f-bs-line {
    left: 10px;
    bottom: 0;
    height: 1px;
    width: 60px;
    content: "";
    position: absolute;
    background: #ae97a2;
    transform-origin: 0px 0px;
    transition: all 0.5s ease-out;
  }

  .f-bs-label {
    top: 0%;
    left: 100px;
    bottom: 0;
    height: 1px;
    content: "";
    position: absolute;
    display: block;
    font-style: normal;
    transform-origin: 0px 0px;
    transition: all 0.5s ease-out;
  }

  &:hover {
    .f-bs-arrow {
      top: -5px;
      width: 17px;
      height: 20px;
      fill: #ae97a2;
      position: absolute;
      opacity: 1;

      &:nth-child(1) {
        opacity: 0;
        left: 150px;
        transition: all 0.5s ease-in-out;
      }
      &:nth-child(2) {
        opacity: 1;
        right: 0px;
        transition: all 0.5s ease-in-out;
      }
    }

    .f-bs-line {
      left: 220px;
      bottom: 0;
      height: 1px;
      width: 60px;
      content: "";
      position: absolute;
      background: #ae97a2;
      transform: translateX(-20px);
    }

    .f-bs-label {
      top: 0%;
      left: 40px;
      transform-origin: -20px 0px;
      transition: all 0.5s ease-out;
    }
  }
}
