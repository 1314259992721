*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  box-sizing: inherit;
}

*:focus {
  //outline: 0 !important;

  //  outline: none;
  //  background-color: black;
  //  color: white;
  outline: 1px dashed red;
}

html {
  // This defines what 1 rem is
  font-size: 62.5%; // 1 rem= 10px; 10px/16px = 62.5%s
  @include respond(tab-land) {
    // width < 1200 ?
    font-size: 56.25%; // 1 rem = 9x, 9px/16px = 50%
  }

  @include respond(tab-port) {
    // width < 900 ?
    font-size: 50%; // 1 rem = 8px, 8px/16px = 50%
  }

  @include respond(big-desktop) {
    font-size: 75%; // 1 rem = 12x, 12px/16px =
  }

  // For Ipad and mobile
  @media (max-width: 1200px) {
    overflow-x: hidden;
  }
}

body {
  box-sizing: border-box;
  background-color: #ffffff;
  overflow-x: hidden;
  display: grid;

  @include respond(tab-port) {
    padding: 0;
  }
}
