$rt-namespace: "Toastify";
$rt-toast-width: 320px !default;
$rt-toast-background: #fff !default;
$rt-toast-min-height: 64px !default;
$rt-toast-max-height: 800px !default;

//$rt-color-default: #fff !default;

$rt-color-default: $color-primary-dark !default;
$rt-color-dark: #121212 !default;
$rt-color-info: #3498db !default;
//$rt-color-success: #07bc0c !default;
$rt-color-success: $color-tertiary-dark !default;

$rt-color-warning: #f1c40f !default;
//$rt-color-error: #e74c3c !default;
$rt-color-error: #7c2117 !default;

$rt-text-color-default: #aaa !default;
$rt-text-color-dark: #fff !default;

$rt-color-progress-default: linear-gradient(
  to right,
  #4cd964,
  #5ac8fa,
  #007aff,
  #34aadc,
  #5856d6,
  #ff2d55
) !default;
$rt-color-progress-dark: #bb86fc !default;
$rt-mobile: "only screen and (max-width : 480px)" !default;
$rt-font-family: sans-serif !default;
$rt-z-index: 9999 !default;
