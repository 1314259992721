.drawingsvg {
  position: absolute;
  top: 0rem;
  width: 100%;
  height: 100vh;
  fill: none;
  stroke: black;
  stroke-width: 0.5;
  z-index: 1;
  background: aliceblue;
}
