.l-story {
  position: relative;
  display: block;
  padding-top: 120px;
  margin-bottom: 300px;
  z-index: 1;

  h1 {
    align-self: center;
    text-align: center;
    justify-self: center;
    text-transform: capitalize;
    color: $color-primary-dark;
    position: relative;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 64px;
    animation: arriveRight 3s ease-in-out;
  }

  &__main {
    color: $color-text-antracite;
    display: grid;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-template-columns: repeat(2, 500px);
    grid-template-rows: auto auto auto auto;
    width: 1000px;
    margin-left: auto;
    margin-right: auto;
    grid-row-gap: 64px;
    position: relative;
    animation: rise 4s ease-in-out;

    .image-1 {
      grid-row-start: 1;
      grid-column-start: 2;
      transform: translateX(-50%);
      width: 1000px;
    }

    .image-2 {
      grid-row-start: 3;
      grid-column-start: 1;
      margin-top: 64px;
      width: 500px;
    }

    .image-3 {
      grid-row-start: 4;
      grid-column-start: 2;
      margin-top: 64px;
      width: 500px;
    }

    .text-1 {
      align-self: center;
      grid-row-start: 2;
      grid-column-start: 2;
      margin-top: 64px;
      transform: translateX(-50%);
      width: 700px;
    }

    .text-2 {
      grid-row-start: 3;
      grid-column-start: 2;
      margin-top: 64px;
      width: 80%;
      justify-self: center;
      align-self: start;
    }

    .text-3 {
      align-self: start;
      grid-row-start: 4;
      grid-column-start: 1;
      margin-top: 64px;
      width: 80%;
    }
  }
}

.visible {
  visibility: visible !important;
}

//      Ipad-Pro Styles Starts Here
@media (max-width: 1200px) and (min-width: 766px) {
  .l-story {
    padding-bottom: 0px;
    margin-bottom: 50%;
    &__main {
      grid-template-columns: repeat(2, 300px);
      width: 600px;
      .image-1 {
        width: 80vw;
      }

      .text-1 {
        margin-top: 0%;
        width: 500px;
      }
      .image-2 {
        margin-top: 0%;
        width: 40vw;
      }
      .text-2 {
        align-self: start;
        margin-top: 0%;
      }

      .image-3 {
        margin-top: 120px;
        width: 40vw;
      }
      .text-3 {
        align-self: start;
        margin-top: 120px;
      }
    }
  }
}
//      Ipad-Pro Styles Ends Here

//      Mobile Styles Starts Here
@media (max-width: 765px) and (min-width: 300px) {
  .l-story {
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    padding-bottom: 0%;
    margin-bottom: 0%;

    h1 {
      grid-column-start: 1;
      margin-bottom: 0px;
    }
    &__main {
      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-gap: 48px;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      margin-left: 10vw;
      margin-right: 10vw;
      padding-top: 48px;

      .image-1 {
        width: 80vw;
        grid-column-start: 1;
        transform: unset;
      }

      .text-1 {
        margin-top: 0%;
        width: 80vw;
        grid-column-start: 1;
        transform: unset;
        text-align: center;
      }
      .image-2 {
        margin-top: 0%;
        width: 80vw;
        grid-column-start: 1;
      }
      .text-2 {
        align-self: start;
        margin-top: 0%;
        grid-column-start: 1;
        grid-row-start: 4;
        text-align: center;
        width: 80vw;
        justify-self: unset;
      }

      .image-3 {
        margin-top: 0px;
        width: 80vw;
        grid-column-start: 1;
        grid-row-start: 5;
      }
      .text-3 {
        align-self: start;
        margin-top: 0px;
        grid-column-start: 1;
        grid-row-start: 6;
        width: 80vw;
      }
    }
  }
}
//      Mobile Styles Ends Here
