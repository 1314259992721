.input {
  position: relative;
  z-index: 1;
  display: inline-block;
  margin: 2px;
  max-width: 300px !important;
  min-width: 300px !important;
  min-height: 75px !important;
  max-height: 75px !important;
  width: calc(100% - 2em);
  vertical-align: top;
  margin: unset;
  padding-bottom: 5%;
  stroke-dashoffset: 0px !important;
}

.input__field {
  position: relative;
  display: block;
  float: right;
  border: none;
  border-radius: 0;
  background: #f0f0f0;
  color: #aaa;
  -webkit-appearance: none; /* for box shadows to show on iOS */

  stroke-dashoffset: 0px !important;
}

.input__field:focus {
  outline: none;
}

.input__label {
  display: inline-block;
  float: right;
  padding: 0 2px;
  width: 40%;
  font-size: 16px;
  color: white;
  font-weight: 100;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.input__label-content {
  position: relative;
  display: block;
  padding: 13px;
  font-weight: 100;
  width: 100%;
}

.graphic {
  position: absolute;
  top: -20px;
  left: 0;
  fill: none;
}

/* Individual styles */

/* Nao */
.input--nao {
  overflow: hidden;
  padding-top: 5px;
}

.input__field--nao {
  width: 80%;
  background: transparent;
  color: white;
  position: absolute;
  top: 16px;
}

.input__label--nao {
  position: absolute;
  top: 40px;
  left: 0;
  display: block;
  width: 100%;
  text-align: left;
  padding: 0em;
  pointer-events: none;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transition: -webkit-transform 0.3s 0.1s, color 1s;
  transition: transform 0.3s 0.1s, color 1s;
  -webkit-transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
}

.graphic--nao {
  stroke: white;
  pointer-events: none;
  -webkit-transition: -webkit-transform 0.7s, stroke 0.7s;
  transition: transform 0.7s, stroke 0.7s;
  -webkit-transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
}

.input__field--nao:focus + .input__label--nao,
.input--filled .input__label--nao {
  color: white;
  -webkit-transform: translate3d(0, -4.5rem, 0) scale3d(0.75, 0.75, 1);
  transform: translate3d(0, -4.5rem, 0) scale3d(0.75, 0.75, 1);
}

.input__field--nao:focus ~ .graphic--nao,
.input--filled .graphic--nao {
  stroke: white;
  -webkit-transform: translate3d(-66.6%, 0, 0);
  transform: translate3d(-66.6%, 0, 0);
}

#bar {
  stroke-dashoffset: 0px !important;
}
