.c-cart {
  width: 100vw;
  padding: 0;
  padding-top: 60px;
  margin-bottom: 300px;
  background: unset;
  color: $color-text-antracite;
  display: grid;
  grid-template-rows: 15rem 80px auto;
  grid-row-gap: 32px;

  h1 {
    align-self: center;
    text-align: center;
    grid-row-start: 1;
    text-transform: capitalize;
    color: $color-primary-dark;
    animation: arriveRight 1.5s ease-in-out;
  }

  .step-index {
    grid-row-start: 2;
    position: relative;
    top: unset !important;
  }

  .b-layout-card {
    height: unset !important;
    margin-top: unset !important;
    width: 1200px;
  }

  &__table {
    position: relative;
    background-color: $color-white;
    padding: 32px;
    padding-left: 128px;
    padding-right: 128px;
    grid-row-start: 3;
    transform-origin: center;
    animation: rise 2s ease-in-out;

    h5 {
      color: inherit;
      justify-self: start;
      text-transform: capitalize;
      margin-bottom: 32px;
    }

    .table-head {
      color: $color-text-antracite;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      padding-bottom: 6px;
      border-bottom: 1px solid $color-tertiary-dark;
      margin-bottom: 16px;

      h4 {
        color: inherit;
        justify-self: center;
        text-transform: capitalize;
      }
    }

    .table-item {
      border-bottom: 1px solid $color-tertiary-dark;
      color: inherit;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      text-transform: capitalize;
      margin-top: 16px;
      margin-bottom: 16px;
      padding-bottom: 32px;

      div {
        align-self: center;
        justify-self: center;

        img {
          width: 10rem;
        }

        .qty-box {
          align-self: center;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          column-gap: 2rem;
          justify-self: center;
          &__btn {
            align-self: center;
            transform: scale(0.75);
            transform-origin: center;
            transition: all 0.5s ease-in-out;

            &:hover {
              transform: scale(1.4);
            }
          }
        }

        .remove {
          font-size: 1.6rem;
        }
      }
    }

    .total {
      color: inherit;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      padding-top: 16px;
      margin-bottom: 32px;
      text-align: center;

      &__text {
        grid-column-start: 5;
      }

      &__number {
        grid-column-start: 6;
      }
    }
  }

  &__buttons {
    display: grid;
    grid-template-columns: auto auto;
    row-gap: 3rem;
    padding-top: 3rem;
    margin-bottom: 64px;
    margin-left: 64px;
    margin-right: 64px;

    a {
      white-space: nowrap;
    }

    a:nth-child(1) {
      grid-row-start: 1;
      grid-column-start: 2;
      justify-self: end;
    }

    a:nth-child(2) {
      grid-row-start: 1;
      grid-column-start: 1;
    }
  }

  &__empty {
    width: 100vw;
    padding: 0;
    padding-top: 60px;
    padding-bottom: 400px;
    background: unset;
    color: $color-text-antracite;
    display: grid;
    grid-template-rows: 15rem auto;
    grid-row-gap: 32px;

    h1 {
      align-self: center;
      text-align: center;
      grid-row-start: 1;
      text-transform: capitalize;
      color: $color-primary-dark;
      animation: arriveRight 3s ease-in-out;
    }

    .step-index {
      grid-row-start: 2;
      position: relative;
      top: unset !important;
      width: 80vw;
      .step-grid {
        grid-template-columns: repeat(4, 20vw);
        width: 80vw;

        .line {
          width: 80vw;
        }
      }
    }

    .h5-didot-reg {
      align-self: start;
      justify-self: center;
      grid-column-start: 3;
      font-size: 32px;
      line-height: 48px;
      font-family: "Didot";
      width: max-content;
    }

    .b-layout-card {
      height: unset !important;
      margin-top: unset !important;
      padding: 32px;
      padding-left: 0%;
      padding-right: 0%;
      height: 780px;
      min-height: 780px;
      max-height: 780px;
      grid-template-columns: repeat(5, 200px);
      grid-template-rows: 150px 300px 100px 180px;
      animation: rise 2s ease-in-out;

      #cloudia-empty {
        grid-row-start: 2;
        grid-column-start: 2;
        transform: translateX(-100px);
        z-index: 1;
      }

      #little-heart {
        align-self: center;
        justify-self: center;
        grid-column-start: 4;
        grid-row-start: 2;
        transform: scale(2.5);
        z-index: 1;
      }

      #empty-box {
        align-self: end;
        justify-self: center;
        grid-column-start: 3;
        grid-row-start: 2;
        z-index: 1;
      }

      #top-leaf {
        align-self: center;
        justify-self: center;
        grid-column-start: 1;
        grid-row-start: 2;
        transform: translateX(70px);
        z-index: 0;
      }

      #plant-left {
        align-self: center;
        justify-self: center;
        grid-column-start: 5;
        grid-row-start: 2;
        transform: translateX(-115px);
        z-index: 0;
      }

      #shadow {
        grid-column-start: 3;
        grid-row-start: 2;
        align-self: center;
        transform: translateY(120px) translateX(-120px);
      }

      .c-cart__buttons {
        align-self: end;
        display: grid;
        grid-column-start: 3;
        grid-row-start: 4;
        justify-self: center;
        transform: translateX(-2.5px);
        grid-template-columns: auto auto;
        grid-row-gap: 3rem;
        row-gap: 3rem;
        padding-top: 3rem;

        margin-left: 64px;
        margin-right: 64px;
      }
    }

    .c-product__related-products {
      grid-row-start: 3 !important;
    }
  }
}

//      Ipad & Tablet Styles Starts Here
@media (max-width: 1200px) and (min-width: 766px) {
  .c-cart {
    padding-bottom: 200px;
    width: 90vw;
    margin-left: auto;
    margin-right: auto;

    .b-layout-card {
      width: 90vw;
      padding-left: 64px;
      padding-right: 64px;

      .table-head {
        column-gap: 32px;
      }
      .table-item {
        column-gap: 32px;
      }

      .total {
        column-gap: 32px;
        &__text {
          font-size: 12px;
          width: max-content;
        }
      }

      .c-cart__buttons {
        margin-left: unset;
        margin-right: unset;
      }
    }

    .step-index {
      grid-row-start: 2;
      position: relative;
      top: unset !important;
      width: 80vw;
      .step-grid {
        grid-template-columns: repeat(4, 20vw);
        width: 80vw;

        .step-line {
          width: 80vw;
        }
      }
    }
  }

  .c-cart__empty {
    padding-bottom: 0px;
    margin-bottom: 400px;
    padding-left: auto;
    padding-right: auto;
    min-height: max-content;

    .b-layout-card {
      grid-template-columns: repeat(5, 16vw);
      margin-left: auto;
      margin-right: auto;
      height: 800px !important;
      min-height: 800px;
      width: 80vw;
      padding-left: 0%;
      padding-right: 0%;
      justify-content: center;

      .h5-didot-reg {
        justify-self: center;
        grid-column-start: 3;
        transform: unset;
      }

      #cloudia-empty {
        transform: translateX(-100px) translateY(0px);
        z-index: 1;
      }

      #plant-left {
        grid-column-start: 4;
      }

      #top-leaf {
        justify-self: unset;
      }

      #shadow {
        transform: translateY(120px) translateX(-60px);
      }
    }
  }
}
//      Ipad & Tablet Styles Ends Here

//      Mobile Styles Starts Here
@media (max-width: 765px) and (min-width: 300px) {
  .c-banner-figure .c-banner-src:nth-child(2) {
    display: none;
  }

  .c-cart {
    width: 100vw;
    padding: 16px;
    padding-top: 80px;
    text-align: center;
    background: unset;
    display: grid;
    grid-template-rows: 15rem auto 1fr;
    grid-row-gap: 80px;
    animation: fadeIn 4s ease-in-out;
    position: relative;
    margin-bottom: unset;
    .step-index {
      width: 80vw;
      .step-grid {
        grid-template-columns: repeat(4, 20vw);
        width: 80vw;

        .step-line {
          width: 80vw;
        }
      }
    }

    h1 {
      align-self: center;
      font-size: 5rem;
    }

    &__empty {
      padding-top: 0px;
      padding-bottom: 0px;
      margin-bottom: 0px;
      grid-row-gap: 64px;

      h1 {
        margin-top: 120px;
      }

      .card-empty {
        min-height: auto;
        width: 90vw;
        max-width: 90vw;
        min-width: 90vw;
        grid-template-columns: repeat(5, 18vw);
        grid-template-rows: repeat(4, 90px);
        justify-content: center;
        margin: auto;
        margin-top: 120px;

        .h5-didot-reg {
          font-size: inherit;
          grid-column-start: 3;
          grid-column-end: 3;
        }

        .c-cart__buttons {
          margin: 0%;
          width: 100%;
          grid-column-start: 1;
          grid-column-end: 6;
          transform: unset;
          justify-self: center;
        }

        #cloudia-empty {
          transform: translateY(-21%) translateX(-40%) scale(0.4);
          z-index: 1;
        }

        #little-heart {
          transform: scale(1.5);
          align-self: end;
          grid-row-start: 2;
        }
        #top-leaf {
          transform: scale(0.5);
          grid-column-start: 2;
          z-index: 0;
        }
        #plant-left {
          transform: translateY(20%) translateX(34px) scale(0.3);
          grid-column-start: 3;
        }
        #empty-box {
          grid-row-start: 3;
          transform: scale(0.5);
        }
        #shadow {
          transform: scale(0.3) translateX(-100%);
          grid-column-start: 2;
          grid-row-start: 3;
        }
      }
    }

    &__buttons {
      margin-bottom: 12px;
      height: 80px;
      width: 200px;
      grid-template-columns: auto;
      grid-template-rows: auto auto;
      margin-left: 0%;
      margin-right: auto;
      grid-column-start: 1;
      grid-column-end: 3;
      row-gap: 16px;
      a {
        &:nth-child(1) {
          grid-column-start: 1;
          grid-row-start: 1;
        }

        &:nth-child(2) {
          grid-column-start: 1;
          grid-row-start: 2;
        }
      }
    }

    &__table {
      height: 320px !important;
      margin-top: unset !important;
      width: 90vw !important;
      width: 90vw;
      min-width: 90vw;
      max-width: 90vw;
      min-height: 600px;
      padding: 16px;
      grid-template-columns: 1fr;
      .h5-didot-reg {
        height: 50px;
        margin-bottom: 0px;
      }

      .table-head {
        height: 50px;
        grid-column-start: 1;
        grid-column-end: 3;
        grid-template-columns: 75px 1fr 1fr 1fr 1fr 1fr;
        column-gap: 6px;
        padding-bottom: 0px;
        margin-bottom: 0px;
        .label {
          font-size: 10px;
        }
      }

      .table-item {
        height: 150px;
        grid-column-start: 1;
        grid-column-end: 3;
        grid-template-columns: 75px 1fr 1fr 1fr 1fr 1fr;
        column-gap: 6px;
        margin-top: 0px;
        margin-bottom: 0px;
        padding-bottom: 0px;

        div {
          img {
            width: 70px;
          }
          .qty-box {
            grid-template-columns: 1fr;
            grid-template-rows: repeat(3, 1fr);
            column-gap: 3px;
            &__btn {
              align-self: center;
              transform: scale(0.5);
              grid-row-start: 2;

              transform-origin: center;
              transition: all 0.5s ease-in-out;

              &:hover {
                transform: scale(0.6);
              }
            }

            h2 {
              align-self: center;
              grid-row-start: 2;
            }
          }
        }

        .remove {
          transform: scale(0.5);
        }
      }

      .total {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-template-columns: 75px 1fr 1fr 1fr auto auto;
        padding-top: unset;
        margin-bottom: unset;
        height: 40px;
        column-gap: 6px;

        &__text {
          align-self: center;
          width: max-content;
          font-size: 12px;
          width: 70px;
        }

        &__number {
          align-self: center;
        }
      }
    }
  }
}
//      Mobile Styles Ends Here
