.l-register {
  background-color: $color-white;
  color: $color-primary-light;
  display: grid;
  grid-template-columns: repeat(4, auto);
  width: 100%;
  height: 100%;
  padding-top: 60px;
  margin-bottom: 300px;

  h1 {
    align-self: center;
    text-align: center;
    grid-row-start: 1;
    grid-column-start: 2;
    grid-column-end: 4;
    padding-top: 48px;
    text-transform: capitalize;
    color: $color-primary-dark;
    animation: arriveRight 2s ease-in-out;
  }

  .input {
    margin: unset;
    max-width: 80vw !important;
    max-width: unset !important;
    min-width: unset !important;
    z-index: 0;
  }

  .input__field--nao {
    color: $color-primary-dark;
  }

  .graphic--nao {
    stroke: $color-primary-dark;
  }

  .input__field--nao:focus + .input__label--nao,
  .input--filled .input__label--nao {
    color: $color-primary-dark;
  }

  .input__field--nao:focus ~ .graphic--nao,
  .input--filled .graphic--nao {
    stroke: $color-primary-dark;
  }

  .label {
    color: $color-primary-dark;
  }

  .b-layout-card {
    min-width: 40vw;
    max-height: 65rem;
  }

  &__login {
    grid-column-start: 2;
    color: $color-text-antracite;
    display: grid;
    grid-template-rows: 5rem 20rem 5rem;
    grid-row-gap: 5rem;
    padding: 32px;
    padding-left: 64px;
    padding-right: 64px;
    grid-row-gap: 80px;
    margin-top: 64px;
    animation: moveInUp 3s ease-in-out;

    h3 {
      grid-row-start: 1;
    }

    form {
      display: grid;
      grid-template-rows: 5rem 5rem;
      grid-row-gap: 64px;
      margin-left: 60px;
      width: 400px;

      #mail {
        grid-row-start: 2;
      }
      #password {
        grid-row-start: 3;
      }
    }

    .action {
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-row-start: 3;
      row-gap: 1rem;
      margin-left: 60px;
      // animation: arriveLeft 3s ease-in-out;

      .remember {
        grid-row-start: 1;
        display: grid;
        grid-template-columns: 1fr 1rem auto 3rem;

        .MuiCheckbox-colorSecondary.Mui-checked {
          color: $color-primary-dark !important;
        }
        .f-bp {
          grid-column-start: 1;
          grid-row-start: 1;
        }

        div {
          display: grid;
          grid-template-columns: auto auto;
          grid-column-start: 3;

          > * {
            align-self: center;
            margin-right: 0.5rem;
          }
        }
      }

      a {
        grid-row-start: 1;
        p {
          margin-top: 2.5rem;
        }
      }
    }
  }

  &__register {
    display: grid;
    grid-column-start: 3;
    color: $color-text-antracite;
    display: grid;
    grid-template-rows: 5rem 20rem 5rem;
    grid-row-gap: 80px;
    padding: 32px;
    padding-left: 64px;
    padding-right: 64px;
    margin-top: 64px;

    animation: moveInUp 3s ease-in-out;

    h3 {
      //color: $color-primary-dark;
      grid-row-start: 1;
    }

    form {
      display: grid;
      grid-template-rows: 5rem 5rem;
      grid-row-gap: 64px;
      margin-left: 60px;
      width: 400px;
    }

    .action {
      grid-row-start: 3;
      margin-left: 60px;
      // animation: arriveRight 3s ease-in-out;
    }
  }

  &__account {
    background-color: $color-secondary-light;
    display: block;
    width: 100%;
    padding-top: 15vh;
    padding-bottom: 5%;
    position: relative;
    // animation: arriveLeft 2s ease-in-out;

    h3 {
      margin-top: 3%;
      text-align: center;
      font-family: $font-didot;
      font-size: 7rem;
      font-weight: 800;
      text-transform: capitalize;
      letter-spacing: 1rem;
      color: #d28e77;
      animation: arriveRight 3s ease-in-out;
    }

    .left-menu {
      grid-row-start: 2;
      background-color: $color-secondary-dark;
      padding: 5rem;
      width: 75rem;
      min-width: 75rem;
      margin-left: auto;
      margin-right: auto;
      min-height: 65rem;
      /* border-radius: 4px; */
      animation: land-from-left 2s ease-in-out;

      &--title {
        font-size: 2.5rem;
        color: $color-primary-dark;
        margin-bottom: 5rem;
        text-align: center;
        letter-spacing: 0.7rem;
      }
      form {
        .form-group {
          font-size: 1.4rem;
          font-weight: 200;
          display: grid;
          grid-template-columns: 1fr 1fr;
          padding-left: 1rem;
          margin-left: auto;
          margin-right: auto;
          margin-top: 1.3rem;
          width: 50rem;
          height: 3rem;

          .field-label {
            align-self: center;
            justify-self: start;
            color: $color-primary-light;
            font-weight: 600;
          }

          input {
            appearance: textfield;
            background-color: $color-secondary-light;
            border-style: unset;
            border-color: unset;
            border-image: unset;
            color: $color-primary-dark !important;
            cursor: text;
            display: inline-block;
            font: inherit;
            font-size: 1.5rem;
            font-weight: 700;
            justify-self: start;
            letter-spacing: normal;
            padding-left: 1rem;
            text-align: start;
            text-indent: 0px;
            text-rendering: auto;
            text-transform: none;
            text-shadow: none;
            margin: 0em;
            min-height: 3.5rem;
            max-height: 3.5rem;
            -webkit-appearance: textfield;
            -webkit-rtl-ordering: logical;
            -webkit-writing-mode: horizontal-tb !important;
            word-spacing: normal;

            &:focus {
              background-color: $color-secondary-light;
              padding: 2%;
              outline: none;
            }

            &::placeholder {
              color: $color-primary-light !important;
              font-size: $font-small;
            }
          }

          .srv-validation-message {
            position: relative !important;
            top: -1rem !important;
            color: $color-primary-light !important;
          }

          a {
            grid-column-start: 2;
            margin-top: 3rem;
          }
        }

        .account {
          grid-template-columns: 3rem 1fr;

          input {
            align-self: center;
            grid-column-start: 1;
            grid-row-start: 1;
          }
          label {
            align-self: center;
            color: $color-primary-light;
            font-weight: 600;
            grid-column-start: 2;
            grid-row-start: 1;
          }
        }
      }
    }
  }
}

//      Ipad-Pro & Tablet Styles Starts Here
@media (max-width: 1200px) and (min-width: 766px) {
  .l-register {
    grid-template-columns: auto 90vw auto;
    grid-template-rows: auto;
    column-gap: 0;
    grid-column-gap: 0;
    height: max-content;
    margin-bottom: 300px;
    padding-bottom: 0%;

    h1 {
      align-self: start;
      grid-column-start: 1;
    }

    .input {
      width: 100%;
    }

    &__login {
      grid-row-start: 1;
      grid-column-start: 2;
      margin-top: 15vh;
      margin-bottom: 0%;
      grid-template-rows: 5rem 20rem 15rem;
      padding-bottom: 33px;
      .action {
        grid-template-rows: 10rem 1fr;
        .remember {
          grid-row-start: 1;
          display: grid;
          grid-template-rows: auto auto;
          div {
            grid-template-columns: 30px auto;
            grid-row-start: 2;
            grid-column-start: 1;

            span {
              justify-self: unset;
              width: 24px;
            }
          }
        }
      }
    }

    &__register {
      grid-row-start: 2;
      grid-column-start: 2;
      margin-top: 128px;
      margin-bottom: 15vh;
    }
  }
}
//      Ipad & Tablet Styles Ends Here

//      Mobile Styles Starts Here
@media (max-width: 765px) and (min-width: 300px) {
  .l-register {
    padding-top: 120px;
    padding-bottom: 0px;
    grid-template-columns: 1fr;
    grid-template-rows: 180px 75rem 65rem;
    grid-row-gap: 64px;
    padding-top: 0%;

    .h2-didot-reg {
      grid-row-start: 1;
      padding-top: 120px;
      position: absolute;
      width: 100%;
    }

    form {
      margin-left: unset;
      width: 79vw;
    }

    .action {
      margin-top: 32px;
      margin-left: 0px;
      margin-left: unset;
      width: 79vw;
      .remember {
        grid-row-start: 1;
        row-gap: 12px;

        div {
          grid-template-columns: 30px auto;
          grid-column-start: 1;
        }
      }
    }

    &__login {
      grid-column-start: 1;
      grid-row-start: 2;
      grid-template-rows: auto;
      grid-row-gap: 0px;
      padding: 18px;
      margin: 5vw;
      width: 90vw;
      min-width: 90vw !important;

      .h5-didot-reg {
        text-align: center;
      }
    }

    &__register {
      grid-column-start: 1;
      grid-row-start: 3;
      grid-template-rows: auto;
      grid-row-gap: 0px;
      padding: 18px;
      margin: 5vw;
      width: 90vw;
      min-width: 90vw;

      .h5-didot-reg {
        text-align: center;
      }
    }
  }
}
//      Mobile Styles Ends Here

// Style for the Post Layout Ends
