.step-index {
  position: relative;
  text-align: center;
  width: 825px;
  height: 24px;
  margin-left: auto;
  margin-right: auto;

  .step-grid {
    color: $color-grey-light-2;
    display: grid;
    grid-template-columns: repeat(4, 200px);
    grid-template-rows: repeat(2, 1fr);
    grid-row-gap: 24px;
    position: absolute;
    margin-top: -12px;
    width: 800px;

    .current-step {
      color: $color-text-antracite !important;
    }
    .current-step-circle {
      background-color: $color-primary-light !important;
      z-index: 1;
    }

    .step1 {
      grid-column-start: 1;
      grid-column-end: 2;
      justify-self: center;
      grid-row-start: 2;
      background: #e3dddd;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      margin-top: -12px;
    }

    .step2 {
      grid-column-start: 2;
      grid-column-end: 2;
      justify-self: center;
      grid-row-start: 2;
      background: #e3dddd;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      margin-top: -12px;
    }

    .step3 {
      grid-column-start: 3;
      grid-column-end: 4;
      justify-self: center;
      grid-row-start: 2;
      background: #e3dddd;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      margin-top: -12px;
    }

    .step4 {
      grid-column-start: 4;
      grid-column-end: 5;
      justify-self: center;
      grid-row-start: 2;
      background: #e3dddd;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      margin-top: -12px;
    }
  }

  .step-line {
    background-color: $color-grey-light-2;
    grid-column-start: 1;
    grid-row-start: 2;
    border-radius: 6px;
    position: relative;
    height: 1px;
    width: 800px;
  }

  .step-text {
    justify-self: center;
    grid-row-start: 1;

    &__1 {
      grid-column-start: 1;
      grid-column-end: 2;
    }
    &__2 {
      grid-column-start: 2;
      grid-column-end: 3;
    }
    &__3 {
      grid-column-start: 3;
      grid-column-end: 4;
    }
    &__4 {
      grid-column-start: 4;
      grid-column-end: 5;
    }
  }
}
