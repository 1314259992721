.l-privacy {
  padding-top: 120px;
  margin-bottom: 120px;

  h1 {
    align-self: center;
    text-align: center;
    grid-row-start: 1;
    text-transform: capitalize;
    color: $color-primary-dark;
    animation: arriveRight 1.5s ease-in-out;
  }

  &--canvas {
    color: $color-text-antracite;
    padding: 128px;
    position: relative;
    animation: land-from-left 2s ease-in-out;

    .MuiTypography-body1 {
      font-size: 16px !important;
      font-family: inherit !important;
      font-weight: 600 !important;
      line-height: 1.5 !important;
      letter-spacing: 0.00938em !important;
    }

    .MuiSvgIcon-root {
      width: 3em !important;
      height: 2em !important;
    }

    .MuiList-subheader {
      border-bottom: 1px solid $color-tertiary-dark;
      margin-bottom: 48px;
    }

    .card-body {
      padding: 16px;
      padding-top: 0%;
      width: 80%;

      p {
        padding: 16px;
        padding-top: 0;
      }
    }
  }
}

//      Ipad Styles Starts Here
@media (max-width: 1025) and (min-width: 766px) {
  .l-privacy {
    padding-top: 120px;
    margin-bottom: 400px;
  }
}
//      Ipad Styles Ends Here

//      Mobile Styles Starts Here
@media (max-width: 765px) and (min-width: 300px) {
  .l-privacy {
    padding-top: 120px;
    padding-bottom: 0px;
    min-height: unset;
    margin-bottom: 30%;
    h1 {
      font-size: 24px;
    }
  }

  .l-privacy--canvas {
    padding: 6px;
    margin-top: 60px;
    .MuiList-subheader {
      margin-bottom: 16px;
    }
    .MuiTypography-body1 {
      font-size: 15px !important;
    }
    .card-body {
      width: 90vw;

      p {
        font-size: 15px;
      }
    }
  }
}
//      Ipad Styles Ends Here
