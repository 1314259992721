.dot {
  offset-path: path(
    "M416.5 1C456.477 54.9607 521.425 201.607 448.05 324.64C390.583 420.998 284.499 409.772 230.176 539.381C169.712 683.644 416.265 775.499 469.038 792.666C521.508 809.736 708.9 848.83 666.924 1080.64C619.909 1340.28 124.623 1194.07 39.7854 1111.48C-45.0522 1028.88 16.2992 854.336 155.719 905.543C295.139 956.751 245.667 1119.73 185.701 1196.82C125.735 1273.91 13.8003 1455.61 16.2989 1587.21C20.7942 1823.98 353.983 1837.63 324 2168"
  );
  animation: move 16s cubic-bezier(0.78, 0, 0.17, 0.99) infinite alternate;
}

@keyframes move {
  100% {
    offset-distance: 100%;
  }
}

#little-heart {
  width: 50px;
  height: 50px;
}

.atom {
  width: 50px;
  height: 50px;
}

.dot {
  background: #e8b4b9;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: unset;
}

.dot1 {
  background: #e8b4b9;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: unset;
}
/*
section {
  position: relative;
  width: 300px;
  height: 300px;
  margin: auto;
}
svg {
  position: absolute;
  stroke-width: 2px;
}

body {
  background: Azure;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}
*/
