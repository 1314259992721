.c-collection {
  background-color: $color-white;
  display: block;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 200px;
  margin-bottom: 0px;
  position: relative;

  h1 {
    align-self: center;
    text-align: center;
    grid-row-start: 1;
    text-transform: capitalize;
    color: $color-primary-dark;
    animation: arriveRight 1.5s ease-in-out;
  }

  .little-heart-svg {
    position: relative;
    top: 900px;
    left: 69vw;
    animation: floating-back 10s ease-in-out infinite;
  }

  &__product-list {
    width: 90%;
    height: min-content;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10vh;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 10rem;
    grid-column-gap: 10rem;
    column-gap: 10rem;
    grid-row-gap: 10rem;
    row-gap: 10rem;
    animation: rise 2s ease-in-out;

    .product-box {
      margin-left: auto;
      margin-right: auto;
      background-color: $color-secondary-light;
      transition: all 0.6s ease-in-out;
      z-index: 1;

      &:nth-child(1) {
        &:hover {
          transform: scale(1.1) translate(40px, 10px);
          box-shadow: -16px 16px 16px 16px #7c787838;
        }
      }

      &:nth-child(2) {
        &:hover {
          transform: scale(1.1) translate(0px, 10px);
          box-shadow: 0px 0px 16px 16px #7c787838;
        }
      }

      &:nth-child(3) {
        &:hover {
          transform: scale(1.1) translate(-40px, 10px);
          box-shadow: 16px -16px 16px 16px #7c787838;
        }
      }

      &:nth-child(4) {
        &:hover {
          transform: scale(1.1) translate(40px, 10px);
          box-shadow: -16px 16px 16px 16px #7c787838;
        }
      }

      &:nth-child(5) {
        &:hover {
          transform: scale(1.1) translate(0px, 10px);
          box-shadow: 0px 0px 16px 16px #7c787838;
        }
      }

      &:nth-child(6) {
        &:hover {
          transform: scale(1.1) translate(-40px, 10px);
          box-shadow: 16px -16px 16px 16px #7c787838;
        }
      }

      &__title {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        width: 90%;
        margin: auto;
        margin-top: 5%;
        margin-bottom: 2%;
        h5 {
          font-size: 1.5rem;
          letter-spacing: 0.3rem;
          color: $color-primary-dark;
          text-transform: capitalize;
        }
        a {
          grid-column-start: 3;
          justify-self: end;
          svg {
            transition: all 1s ease-in-out;
            &:hover {
              animation: small-pulse 2s ease-in-out infinite;
            }
          }
        }
      }
      &__description {
        display: flex;
        width: 90%;
        margin: auto;
        color: $color-primary-light;

        p {
          margin-right: 1rem;
        }
      }
    }
  }

  .index {
    position: relative;
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
    display: flex;
    justify-content: center;
    grid-column-start: 2;
    grid-row-start: 3;
    button {
      margin: 15px;
    }

    .current-page {
      background-color: $color-primary-dark !important;
      border-radius: 50%;
      color: $color-white;
      width: 33px;
      height: 33px;
    }
  }
}

.c-collection-scroll {
  background-color: $color-secondary-light;
  display: block;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 5%;
  position: absolute;
  animation: arriveLeft 2s ease-in-out;

  &__header {
    margin-top: 3%;
    text-align: center;
    font-size: 7rem;
    letter-spacing: 1rem;
    color: #d28e77;
    animation: arriveRight 3s ease-in-out;
  }

  #pinContainer {
    height: 100vh;
    padding-top: 15vh;
    // background: url(/assets/img/hero.jpg) no-repeat;

    .product-canvas {
      display: grid;
      grid-template-columns: 10rem 1fr 1fr 10rem;
      grid-template-rows: 10rem auto 10rem;
      width: 90%;
      height: 90vh;
      margin-left: auto;
      margin-right: auto;
      background-image: linear-gradient(
          to right bottom,
          rgba($color-primary-light, 0.8),
          rgba($color-primary-dark, 0.8)
        ),
        url(/assets/img/hero.jpg);
      background-size: cover;

      .frame {
        grid-column-start: 2;
        grid-row-start: 2;
      }

      .circle {
        background: $color-secondary-light;
        border-radius: 50%;
        justify-self: center;
        width: 50rem;
        height: 50rem;
        transition: all 3s;

        img {
          transition: all 5s;
        }

        &:hover {
          transform: scale(1.1);

          img {
            transform: translateY(4rem) scale(1.3);
          }
        }
      }

      .frame-text {
        grid-column-start: 3;
        grid-row-start: 2;
        justify-self: center;
        margin-top: 5%;
        width: 80%;
        color: #d28e77;
        h3 {
          font-family: $font-didot-italic;
          font-size: 5rem;
          letter-spacing: 0.8rem;
          transform: translate3d(0.4003px, 0px, 0px);
          margin-bottom: 5%;
        }
        p {
          font-family: $font-didot;
          font-size: 2rem;
          transform: translate3d(0.4003px, 0px, 0px);
        }
      }
    }
  }
}

//      Ipad-Pro Styles Starts Here
@media (max-width: 1200px) and (min-width: 1026px) {
  .c-collection {
    padding-bottom: 500px;
    margin-bottom: 720px;
    .b-layout-section-path {
      transform: scale(1.7);
    }
    &__product-list {
      padding-top: 5vh;
      grid-template-columns: repeat(1, 1fr);
      grid-column-gap: 10vw;
      margin-left: 10vw;
      margin-right: 10vw;
      width: unset;
      .product-box {
        transform: scale(1) !important;
        margin-bottom: 0%;
        margin-left: 0px;
        margin-right: auto;

        &:hover {
          transform: scale(1.2) translate(10px, 10px);
        }
      }
      .index {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 4;
      }
    }
  }
}
//      Ipad-Pro Styles Ends Here

//      Ipad & Tablet Styles Starts Here
@media (max-width: 1025px) and (min-width: 766px) {
  .c-collection {
    padding-bottom: 20vh;
    margin-bottom: 20%;
    .b-layout-section-path {
      transform: scale(1.7);
    }
    &__product-list {
      padding-top: 5vh;
      grid-template-columns: repeat(1, 1fr);
      grid-column-gap: 0vw;
      margin-left: auto;
      margin-right: auto;
      min-width: 700px;
      max-width: 820px;

      .product-box {
        transform: scale(0.9) !important;
        margin-bottom: 0%;
        margin-left: 0px;
        margin-right: auto;

        &:hover {
          transform: scale(1.2) translate(10px, 10px);
        }
      }

      .index {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 4;
      }
    }
  }
}
//      Ipad & Tablet Styles Ends Here

//      Mobile Styles Starts Here
@media (max-width: 765px) and (min-width: 300px) {
  .c-collection {
    padding-top: 16px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    &__product-list {
      padding-top: 32px;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(7, auto);
      column-gap: 0px;
      row-gap: 48px;

      .product-box {
        transform: scale(1);
        margin-bottom: 0%;

        &:nth-child(1) {
          grid-row-start: 1;
          &:hover {
            transform: scale(1) translate(2px, 2px);
            box-shadow: 8px 8px 8px 8px #7c787838;
          }
        }
        &:nth-child(2) {
          grid-row-start: 2;
          &:hover {
            transform: scale(1) translate(2px, 2px);
            box-shadow: 8px 8px 8px 8px #7c787838;
          }
        }
        &:nth-child(3) {
          grid-row-start: 3;
          &:hover {
            transform: scale(1) translate(2px, 2px);
            box-shadow: 8px 8px 8px 8px #7c787838;
          }
        }
        &:nth-child(4) {
          grid-row-start: 4;
          &:hover {
            transform: scale(1) translate(2px, 2px);
            box-shadow: 8px 8px 8px 8px #7c787838;
          }
        }
        &:nth-child(5) {
          grid-row-start: 5;
          &:hover {
            transform: scale(1) translate(2px, 2px);
            box-shadow: 8px 8px 8px 8px #7c787838;
          }
        }
        &:nth-child(6) {
          grid-row-start: 6;
          &:hover {
            transform: scale(1) translate(2px, 2px);
            box-shadow: 8px 8px 8px 8px #7c787838;
          }
        }
      }

      .index {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 7;
      }
    }
  }
}
//      Mobile Styles Ends Here
