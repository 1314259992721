//     -----------   Checkout  Layout  Starts          ---------------------------------------     //

.c-checkout {
  width: 100vw;
  min-height: 1200px;
  padding: 0;
  padding-top: 60px;
  padding-bottom: 400px;
  margin-bottom: 300px;
  background: unset;
  color: $color-text-antracite;
  display: grid;
  grid-template-rows: 15rem 80px auto;
  grid-row-gap: 32px;
  animation: fadeIn 4s ease-in-out;

  &__title {
    align-self: center;
    text-align: center;
    grid-row-start: 1;
    text-transform: capitalize;
    color: $color-primary-dark;

    animation: arriveRight 3s ease-in-out;
  }

  .step-index {
    grid-row-start: 2;
    animation: rise 2s ease-in-out;
  }

  &__menus {
    display: grid;
    grid-template-columns: auto auto;

    .left-menu {
      position: relative;
      background-color: $color-white;
      padding: 32px;
      padding-left: 80px;
      padding-right: 80px;
      grid-row-start: 3;
      grid-template-rows: 60px auto;
      transform-origin: center;
      margin-left: 15%;
      animation: land-from-left 2s ease-in-out;

      &--title {
        align-self: end;

        text-align: start;
      }

      form {
        margin-top: 32px;
        margin-bottom: 6px;
        .form-group {
          display: grid;
          grid-template-columns: 1fr 1fr;
          margin-left: auto;
          margin-right: auto;
          margin-top: 16px;

          .field-label {
            align-self: end;
            justify-self: start;
          }

          input {
            align-self: end;
            background-color: $color-grey-light-1;
            border-style: unset;
            border-color: unset;
            border-image: unset;
            color: $color-grey-dark !important;
            cursor: text;
            display: inline-block;
            font: inherit;
            font-size: 16px;
            /* font-weight: 700; */
            justify-self: start;
            letter-spacing: normal;
            padding-left: 1rem;
            text-align: start;
            text-indent: 0px;
            text-rendering: auto;
            text-transform: none;
            text-shadow: none;
            margin: 0em;
            min-height: 32px;
            max-height: 32px;
            -webkit-appearance: textfield;
            -webkit-rtl-ordering: logical;
            -webkit-writing-mode: horizontal-tb !important;
            word-spacing: normal;

            &:focus {
              background-color: $color-grey-light-2;
              padding: 2%;
              outline: none;
            }

            &::placeholder {
              color: $color-grey-dark-2 !important;
            }
          }

          .srv-validation-message {
            font-size: 12px;
            position: relative !important;
            top: 0rem !important;
            color: #b00101 !important;
          }
        }

        .account {
          grid-template-columns: 3rem 1fr;

          .PrivateSwitchBase-root-1 {
            padding: 9px;
            padding-left: 0%;
          }

          input {
            align-self: center;
            cursor: unset !important;
            grid-column-start: 1;
            grid-row-start: 1;
          }

          label {
            align-self: center;
            grid-column-start: 2;
            grid-row-start: 1;
          }
        }
      }
    }

    .right-menu {
      position: relative;
      background-color: $color-white;
      padding: 32px;
      padding-left: 80px;
      padding-right: 80px;
      grid-row-start: 3;
      grid-template-rows: 60px auto;
      margin-right: 15%;
      transform-origin: center;
      animation: land-from-right 2s ease-in-out;

      &--title {
        align-self: end;

        text-align: start;
      }

      .checkout-details {
        text-transform: capitalize;
        padding-top: 0rem;

        .order-box {
          padding-bottom: 0%;
          .title-box {
            border-bottom: 1px solid $color-tertiary-dark;
            margin-top: 32px;
            margin-bottom: 6px;
          }
          .qty {
            list-style: none;
            border-bottom: 1px solid $color-tertiary-dark;
            margin-top: 32px;
            margin-bottom: 6px;
            display: grid;
            row-gap: 16px;
            padding-top: 16px;
            padding-bottom: 16px;

            .title-box {
              border-bottom: 1px solid $color-secondary-dark;
            }

            li {
              display: grid;
              grid-template-columns: 35% 5% 35% 30%;
              & > * {
                align-self: center;
                justify-self: center;
                font-size: 1.4rem;

                &:nth-child(1) {
                  justify-self: start;
                }

                &:nth-child(4) {
                  justify-self: start;
                }
              }
            }
          }

          .sub-total {
            list-style: none;
            padding-top: 1rem;
            padding-bottom: 1rem;

            &__number {
              padding-bottom: 1rem;
              display: grid;
              grid-template-columns: 75% 30%;

              span {
                // .price
                font-size: 16px;
                line-height: 20px;
                font-family: $old-standard-tt-medium;
                font-weight: 300;
              }
            }

            &__shipping {
              display: grid;
              grid-template-rows: 2rem 1fr;
              grid-template-columns: 75% 30%;
              margin-top: 3rem;
              padding-top: 3rem;
              border-top: 1px solid $color-tertiary-dark;
              .shipping {
                grid-column-start: 2;
                grid-row-start: 2;
                .shopping-option {
                  justify-self: center;
                  display: grid;
                  grid-template-columns: 3rem auto;
                  grid-column-start: 2;
                  grid-row-start: 2;
                  &:nth-child(2) {
                    grid-column-start: 2;
                    grid-row-start: 2;
                  }

                  input {
                    align-self: center;
                  }

                  p {
                    align-self: center;
                  }
                }
              }
            }
          }
          .total {
            list-style: none;
            padding-bottom: 1rem;
            display: grid;
            grid-template-columns: 75% 30%;

            span {
              //color: #d28e77;
              //font-size: 1.5rem;
              //font-weight: 600;
            }
          }
        }
      }

      .buttons {
        display: grid;
        grid-template-columns: auto auto;
        row-gap: 3rem;
        padding-top: 3rem;

        a {
          white-space: nowrap;
        }

        a:nth-child(1) {
          grid-row-start: 1;
          grid-column-start: 2;
          justify-self: end;
        }

        a:nth-child(2) {
          grid-row-start: 1;
          grid-column-start: 1;
        }
      }
    }
  }

  .payment-box {
    position: relative;
    background-color: $color-white;
    padding: 32px;
    padding-left: 80px;
    padding-right: 80px;
    .upper-box {
      align-self: end;
      .payment-options {
        display: grid;
        grid-template-columns: auto auto;

        .radio-option {
          display: grid;
          grid-template-columns: 2rem auto;

          label {
            align-self: center;
            margin-left: 12px;
          }

          input {
            align-self: center;
          }
        }
      }
    }

    .checkout-form {
      display: grid;
      grid-template-rows: auto 60px;
      margin-top: 32px;
      padding: 32px;
      background: #fffaf0;
      background-color: #fffaf0;
      width: 600px;
      height: 330px;
      border-radius: 16px;

      &__form {
        margin-top: 16px;
        .checkout {
          &--text {
          }
          &--total {
            display: grid;

            .subtitle-lg {
              margin-bottom: 6px;
            }
            grid-template-columns: 7rem auto;
            .label {
              grid-column-start: 1;
            }
            .price {
              grid-column-start: 2;
            }
          }

          &--item {
            margin-top: 16px;
            margin-bottom: 16px;
          }

          &--expiration {
            display: grid;
            grid-template-columns: auto auto;
            text-transform: capitalize;
          }
        }
      }
      &__button {
        margin-top: 2rem;
        align-self: center;
        justify-self: end;
      }
    }
  }
}

.paypal-button {
  margin-top: 2rem;
}

//     -----------   Checkout  Layout  Ends            ---------------------------------------     //

//     -----------   Checkout Success Layout Starts    ---------------------------------------     //

.c-checkout-success {
  background-color: $color-white;
  display: grid;
  grid-template-rows: 15rem 80px auto;
  grid-row-gap: 32px;
  height: 1200px;
  width: 100%;
  padding-top: 0;
  padding-bottom: 5%;
  position: relative;
  color: #d28e77;
  animation: arriveLeft 2s ease-in-out;

  .b-layout-background-svg {
    position: absolute;
  }

  .title {
    align-self: end;
    text-align: center;
    grid-row-start: 1;
    text-transform: capitalize;
    color: $color-primary-dark;
    animation: arriveRight 1.5s ease-in-out;
  }

  .step-index {
    grid-row-start: 2;
  }

  .b-layout-card {
    grid-template-columns: 80px 1fr 80px;
    grid-template-rows: 64px 220px 80px 80px 40px 40px;
    grid-row-start: 3;
    max-width: 700px;
    max-height: 550px;
    min-height: 550px;

    svg {
      width: 208px;
      justify-self: center;
      grid-column-start: 2;
      grid-row-start: 2;
    }

    h3 {
      color: $color-text-antracite;
      justify-self: center;
      grid-column-start: 2;
      grid-row-start: 3;
    }

    p {
      color: $color-text-antracite;
      justify-self: center;
      grid-column-start: 2;
      grid-row-start: 4;
      width: 60%;
      text-align: center;
    }

    a {
      justify-self: center;
      grid-column-start: 2;
      grid-row-start: 5;
    }

    .f-bp-label {
      min-width: max-content;
    }

    .f-bp-new-light-back .right {
      transform: translateX(28px) scaleX(-1);
    }
  }
}

//     -----------   Checkout Success Layout Ends    ---------------------------------------     //

//      Ipad & Tablet Styles Starts Here
@media (max-width: 1200px) and (min-width: 766px) {
  .c-checkout {
    padding-bottom: 200px;
    position: relative;
    height: unset;
  }
  .c-checkout__menus {
    display: grid;
    grid-template-rows: auto auto;
    min-height: 100%;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
    .left-menu {
      grid-row-start: 1;
      margin-left: auto;
    }
    .right-menu {
      margin-top: 10%;
      margin-right: auto;
      grid-row-start: 2;
    }
  }

  .c-checkout-success {
    grid-template-rows: 20rem 80px auto;
    .step-index {
      grid-row-start: 2;
      width: 400px;
      .step-grid {
        grid-template-columns: repeat(4, 100px);
        width: 400px;
        .step-line {
          width: 400px;
        }
      }
    }
    .b-layout-card {
      max-width: 500px;
      min-width: 500px;
    }
  }
}
//      Ipad & Tablet Styles Ends Here

//      Mobile Styles Starts Here
@media (max-width: 765px) and (min-width: 300px) {
  .c-checkout {
    height: 100%;
    padding: unset;
    padding-top: 80px;
    min-height: 900px;
    &__title {
      text-align: center;
      grid-row-start: 1;
    }

    .step-index {
      width: 80vw;
      .step-grid {
        grid-template-columns: repeat(4, 20vw);
        width: 80vw;

        .step-line {
          width: 80vw;
        }
      }
    }

    .payment-box {
      grid-template-rows: 48px 32px 250px 32px 64px;
      padding: 16px;
      min-height: max-content;
      max-height: max-content;
      height: max-content;
      min-width: 95vw;
      max-width: 95vw;
      width: 95vw;
      .h5-didot-reg {
        text-align: center;
        max-width: 85vw;
        min-width: 85vw;
        width: 85vw;
      }

      .upper-box {
        max-width: 85vw;
        min-width: 85vw;
        width: 85vw;
      }

      .text-right {
        .checkout-form {
          max-width: 85vw;
          min-width: 85vw;
          width: 85vw;
          height: unset;
          row-gap: 16px;

          &__form {
            padding-left: 16px;
            padding-right: 16px;
          }
        }
      }
    }

    &__menus {
      display: grid;
      grid-template-columns: unset;
      grid-template-rows: auto auto;
      grid-row-gap: 64px;
      min-height: 100%;
      max-width: 100vw;

      .left-menu {
        grid-row-start: 1;
        padding: 16px;
        width: 90vw;
        min-width: 90vw;
        max-width: 90vw;
        margin-left: 5vw;
        margin-right: 5vw;

        .h5-didot-reg {
          text-align: center;
        }

        form {
          .form-group {
            font-size: 1.4rem;
            font-weight: 200;
            display: grid;
            grid-template-columns: 100px 100px;
            padding-left: 0.5rem;
            /* margin-left: auto; */
            margin-right: auto;
            margin-top: 1.3rem;
            width: 90%;
            height: max-content;

            input {
              font-size: 14px !important;
              font-weight: 700;
              justify-self: start;
              letter-spacing: normal;
              padding-left: 1rem;
              text-align: start;
              margin: 0em;
              width: 40vw;
            }
          }
          .account {
            margin-top: 30px;
          }
        }
      }

      .right-menu {
        min-height: 680px;
        padding: 16px;
        width: 90vw;
        min-width: 90vw;
        max-width: 90vw;
        margin-left: 5vw;
        margin-right: 5vw;

        .h5-didot-reg {
          text-align: center;
        }

        .checkout-details {
          font-size: 1.4rem;
          padding: 12px;
          padding-top: 0rem;

          .order-box {
            .title-box {
              width: 70vw;
            }
            .qty {
              width: 70vw;
            }
            .title-box {
              width: 70vw;
            }
            .sub-total {
              width: 70vw;
            }
          }
        }

        .buttons {
          grid-template-columns: auto;
          row-gap: 6px;
          a {
            &:nth-child(1) {
              grid-row-start: 1;
              grid-column-start: 1;
              justify-self: start;
            }

            &:nth-child(2) {
              grid-row-start: 2;
              grid-column-start: 1;
              justify-self: start;
            }
          }
        }
        .radio-option {
          label {
            align-self: center;
            margin-left: 4px;
          }
        }
      }
    }
  }

  .c-checkout-success {
    grid-template-rows: 20rem 80px auto;
    height: unset;
    .step-index {
      grid-row-start: 2;
      width: 80vw;
      .step-grid {
        grid-template-columns: repeat(4, 20vw);
        width: 80vw;
        .step-line {
          width: 80vw;
        }
      }
    }
    .b-layout-card {
      max-width: 80vw;
      min-width: 80vw;
      p {
        width: unset;
      }
    }
  }
}
//      Mobile Styles Ends Here
