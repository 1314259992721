.hidden {
  position: absolute;
  overflow: hidden;
  width: 0;
  height: 0;
  pointer-events: none;
}

.js .loading::before {
  content: "";
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #efeff2;
}

.js .loading::after {
  content: "";
  position: fixed;
  z-index: 1001;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin: -25px 0 0 -25px;
  pointer-events: none;
  border-radius: 50%;
  background: #dcdcde;
  animation: loaderAnim 0.8s ease-out infinite alternate forwards;
}

@keyframes loaderAnim {
  to {
    transform: scale3d(0.5, 0.5, 1);
  }
}

/* items */
.item {
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0 0 0 auto;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  grid-column: span 2;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.item:nth-of-type(even) {
  margin: 0 auto 0 0;
}

.item:first-of-type {
  grid-column: span 1;
}

.item__svg {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  min-height: 500px;
  margin: 0 auto;
}

.item__clippath,
.item__deco,
.item__img {
  transform-origin: 50% 50%;
}

.item__meta {
  position: absolute;
  top: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.item__number {
  position: absolute;
  top: 0;
  width: 100%;
  padding: 12.5% 0 0 3em;
  letter-spacing: 0.5em;
}

.item__specimen {
  font-size: 5em;
  font-weight: 500;
  line-height: 0.9;
  vertical-align: text-bottom;
  letter-spacing: -0.025em;
}

.item__reference {
  font-size: 1.25em;
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
}

.item__title {
  font-size: 1.165em;
  font-weight: 600;
  width: 100%;
  margin: 1em 0 0 0;
  text-align: center;
  letter-spacing: 0.125em;
  color: #fff;
}

.item__subtitle {
  font-size: 1em;
  font-weight: 500;
  margin: 0.5em 0 0;
  text-align: center;
  letter-spacing: 0.15em;
  opacity: 0;
}

/* Individual styles */
.item--style-1 .item__deco {
  fill: #dcdcde;
}

.item--style-2 .item__deco {
  fill: #404040;
}

.item--style-3 .item__deco {
  fill: #fff;
}

.item--style-3 .item__title {
  color: #131314;
}

.item--style-4 .item__deco {
  fill: #dcdcde;
}

.item--style-5 .item__deco {
  fill: #404040;
}

.item--style-6 .item__deco {
  fill: #dcdcde;
}

.item--style-7 .item__deco {
  fill: #fff;
}

.item--style-7 .item__title {
  color: #131314;
}

.item--style-8 .item__deco {
  fill: #404040;
}

.item--style-9 .item__deco {
  fill: #dcdcde;
}

@media screen and (max-width: 60em) {
  .item:first-of-type {
    grid-column: span 2;
  }
  .content--grid {
    grid-row-gap: 25vh;
  }
}

@media screen and (max-width: 40em) {
  .codrops-header__title {
    font-size: 1em;
  }
  .content--grid {
    padding: 2vh 8vw;
  }
  .item__number {
    font-size: 0.75em;
  }
  .item__svg {
    min-height: 360px;
  }
}
