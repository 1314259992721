.l-404 {
  background: $color-secondary-dark;
  padding-top: 15vh;
  width: 100vw;
  height: 100vh;
  .illustration {
    max-width: max-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15vh;
  }
}
