$color-primary-light: #dbb59d;
$color-primary-dark: #d28e77;

$color-secondary-light: #fffaf0;
$color-secondary-dark: #ffeee3;

$color-tertiary-dark: #fdd8d3;

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #e3dddd;

$color-grey-dark: #999;
$color-grey-dark-2: #808080;
$color-grey-dark-3: #333;

$color-white: #fff;
$color-black: #000;
$color-text-antracite: #292929;

// FONT
$default-font: "Lato-Reg", sans-serif;
$default-font-size: 16px;

// GRID
$grid-width: 160rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 6rem;
$gutter-horizontal-small: 2rem;
$gutter-horizontal: 6rem;
$gutter-horizontal-large: 20rem;

// Template classes are define here
.grid-16 {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-column-gap: 16px;
  padding-left: 32px;
  padding-right: 32px;
  table-layout: fixed;
}
