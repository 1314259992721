.c-header {
  .logo-mobile {
    display: none;
  }
  .header {
    background-color: $color-secondary-light;
    border-bottom: 1px solid #d28e771c;
    position: fixed;
    min-width: 100vw;
    min-height: 80px;
    height: 80px;
    transform: translateY(0%);
    animation: menu-down 0.5s cubic-bezier(0.31, 0.04, 0.06, 0.99);
    z-index: 2;
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    padding-left: 0px;
    padding-right: 0px;
    color: $color-primary-dark;

    .plant-top-corner-svg {
      position: fixed;
      top: 45px;
      left: -50px;
      z-index: 3;
    }

    button {
      align-self: center !important;
      display: block;
      justify-self: center !important;
      transition: all 2s ease-in-out;

      transform: translateY(10px);
      &:nth-child(3) {
        grid-column-start: 10 !important;
        height: 33px !important;
      }
      &:nth-child(4) {
        grid-column-start: 11 !important;
        height: 33px !important;
      }

      &:nth-child(5) {
        grid-column-start: 12 !important;
        height: 33px;

        svg {
          height: 24px;
        }
      }
    }

    a {
      display: block;
      align-self: center;
      justify-self: center;
      transition: all 2s ease-in-out;

      svg {
        height: 50px;
      }

      &:nth-child(4) {
        grid-column-start: 11;
        height: 33px;
      }
      &:nth-child(5) {
        grid-column-start: 12;
      }
      &:nth-child(6) {
        grid-column-start: 13;
        height: 33px;
        transform: translateY(10px);

        svg {
          height: 24px;
        }
      }
      &:nth-child(7) {
        grid-column-start: 14;
        height: 33px;
        transform: translateY(10px);

        svg {
          height: 24px;
        }
      }
      &:nth-child(8) {
        grid-column-start: 15;
        height: 33px;
        transform: translateY(10px);
        svg {
          height: 24px;
        }
      }
      &:nth-child(9) {
        grid-column-start: 16;
        height: 33px;
        align-self: center;
      }
    }
  }

  .header-close {
    opacity: 0;
    user-select: none;
    animation: fadeOut 2s ease-in-out;
    a {
      opacity: 0;

      user-select: none;
    }

    button {
      opacity: 0;

      user-select: none;
    }
  }

  .logo-link {
    height: 60px;
    padding-top: 6px;
    .logo {
      max-width: 265px;
      max-height: 60px !important;

      svg {
        height: 60px;
      }
    }

    &:hover {
      -webkit-animation: rotate1 0.5s ease-in 3s;
      animation: rotate1 0.5s ease-in 3s;
    }
  }

  .rotate {
    max-width: 265px;
    max-height: 80px;
    height: 80px;

    padding-top: 16px;
    -webkit-animation: rotate1 0.5s ease-in 3s;
    animation: rotate1 0.5s ease-in 3s;

    &:hover {
      -webkit-animation: rotate1 0.5s ease-in 3s;
      animation: rotate1 0.5s ease-in 3s;
    }
  }

  .burger {
    display: none;
    &__close {
      display: none;
    }
  }

  .menu {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-column-start: 3;
    justify-content: end;
    list-style-type: none;
    &__item {
      display: flex;
      align-items: flex-end;
      &:nth-child(odd) {
        &:hover {
          transform: scale(1.3) translateX(0.5rem) rotate(2deg);
          transition: all 0.3s ease-in-out;
          transform-origin: center center;
        }
      }

      &:nth-child(even) {
        &:hover {
          transform: scale(1.3) translateX(0.5rem) rotate(-2deg);
          transition: all 0.3s ease-in-out;
          transform-origin: center center;
        }
      }

      a {
        color: $color-primary-dark;
        text-decoration: unset;
        text-transform: capitalize;

        .filledMember {
          position: relative;
          transform-origin: center center;
          animation: floating 4s ease-in-out infinite;
        }

        .filledGift {
          position: relative;
          transform-origin: center center;
          animation: swing 4s ease-in-out infinite;
        }

        .filledHeart {
          position: relative;
          transform-origin: center center;
          animation: small-pulse 3s ease-in-out infinite;
        }
      }
    }

    .subitem {
      visibility: hidden;

      &__shop {
        grid-column-start: 1;
      }
      &__read {
        grid-column-start: 2;
      }
    }

    .search {
      grid-column-start: 3;
    }

    .member {
      grid-column-start: 4;
    }

    .whislist {
      grid-column-start: 5;
    }

    .cart {
      grid-column-start: 6;
    }

    .menu {
      grid-column-start: 7;
    }
  }

  .header.hidden {
    animation: fadeOut 2s ease-in-out;
  }

  .header.shadow {
    box-shadow: 0 9px 9px -9px rgba(0, 0, 0, 0.13);
  }

  .visible {
    visibility: visible !important;
  }

  .menu-slide {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-start: 3;
    justify-content: end;
    list-style-type: none;
    padding-top: 80px;
    position: fixed;
    visibility: hidden;
    width: 100vw;
    height: 100vh;
    z-index: 2;

    &__1 {
      width: 33vw;
      height: 100%;
      background-color: $color-secondary-dark;
      border-left: 2px solid floralwhite;
      order: 3;
    }
    &__2 {
      width: 33vw;
      height: 100%;
      background-color: $color-secondary-dark;
      border-left: 2px solid floralwhite;

      visibility: hidden;
      order: 2;
    }
    &__3 {
      width: 33vw;
      height: 100%;
      background-color: $color-secondary-dark;
      visibility: hidden;
      order: 1;
    }

    ul {
      background-color: $color-secondary-dark;
      color: $color-primary-dark;
      font-size: 1.5rem;
      font-weight: 700;
      display: grid;
      grid-template-rows: repeat(2, 1fr);
      justify-content: unset;
      list-style-type: none;
      position: absolute;
      padding: 3%;
      padding-left: 10%;
      width: 33vw;
      min-height: 30%;

      #all_posts {
        border-bottom: 2px solid $color-tertiary-dark;
        margin-top: 15%;
      }

      li {
        background-color: $color-secondary-dark;
        color: $color-primary-dark;
        font-size: 1.5rem;
        font-weight: 700;
        width: 65%;
        margin-top: 15%;
        text-transform: capitalize;
      }
    }
  }

  .menu-search {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: end;
    list-style-type: none;
    padding-top: 0px;
    position: fixed;
    visibility: hidden;
    width: 100%;
    height: 100%;
    z-index: 1;

    div {
      width: 33vw;
      height: 100%;
      background-color: $color-secondary-dark;
      display: grid;
      grid-column-start: 3;
      grid-template-columns: 5rem 1fr 5rem;
      grid-template-rows: 0rem 1fr 10rem;

      form {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-start: 2;
        grid-row-start: 2;
        height: 60px;
        justify-self: center;
        margin-top: 80px;

        // this overrides the input component
        .content {
          align-self: end;
          padding-top: unset;
          justify-self: center;
          grid-column-start: 1;

          .input {
            margin: unset;
            width: 20vw !important;
            max-width: unset !important;
            min-width: unset !important;
            z-index: 0;
          }

          .input__field--nao {
            position: absolute;
            top: 12px;
            color: $color-primary-dark;
          }

          .graphic--nao {
            stroke: $color-primary-dark;
          }

          .input__field--nao:focus + .input__label--nao,
          .input--filled .input__label--nao {
            color: $color-primary-dark;
          }

          .input__field--nao:focus ~ .graphic--nao,
          .input--filled .graphic--nao {
            stroke: $color-primary-dark;
          }

          .label {
            font-size: 12px;
            color: $color-primary-dark;
          }
        }

        a {
          align-self: end;
          justify-self: start;
          grid-column-start: 2;
          padding-bottom: 2rem;
        }
      }
    }
  }
}
.visible {
  visibility: visible !important;
}

//      Ipad & Tablet Styles Starts Here
@media (max-width: 1200px) and (min-width: 766px) {
  .c-header {
    .logo-mobile {
      display: none;
    }
    .header {
      max-width: 100vw;
      grid-column-gap: 24px;
      padding-left: 32px;
      padding-right: 24px;
      height: 80px;

      .logo {
        svg {
          width: 80px;
        }
      }
      .navbar {
        grid-template-columns: auto auto 70vw;
      }
    }
  }
}
//      Ipad & Tablet Styles Ends Here

//      Mobile Styles Starts Here
@media (max-width: 765px) and (min-width: 300px) {
  .c-header {
    padding-top: 6px;
    padding-left: 0px;
    width: 20vw;
    min-width: 20vw;

    .burger {
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
      background-color: $color-secondary-light;
      display: grid;
      grid-row-start: 0;
      align-content: center;
      justify-content: center;
      width: 80px;
      height: 80px;
      position: fixed;
      right: 0%;

      &__close {
        display: none;
      }
    }

    .logo-mobile {
      display: flex !important;
      position: absolute;
      top: 16px;
      left: 10%;
      right: 10%;
      width: 80%;
      svg {
        width: 265px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .header-close {
      display: none;
      opacity: 0;
      user-select: none;
      animation: fadeOut 2s ease-in-out;
      a {
        display: none;
        opacity: 0;
        transition: all 2s ease-in-out;
        user-select: none;
      }

      button {
        display: none;
        opacity: 0;
        transition: all 2s ease-in-out;
        user-select: none;
      }
    }

    .header {
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
      // display: none;
      grid-template-columns: 20vw;
      grid-template-rows: repeat(7, 10vh);
      height: 100vh;
      max-height: 70vh;
      padding-top: 12px;
      padding-left: 0%;
      padding-right: 0%;
      right: 0%;
      width: 20vw;
      min-width: 20vw;

      .logo {
        display: none;
      }

      .plant-top-corner-svg {
        display: none;
      }

      button {
        &:nth-child(3) {
          grid-row-start: 1;
          grid-column-start: 1 !important;
        }

        &:nth-child(4) {
          grid-row-start: 2;
          grid-column-start: 1 !important;
        }

        &:nth-child(5) {
          grid-row-start: 3;
          grid-column-start: 1 !important;
        }
        &:nth-child(9) {
          // This is the X 'close menu'
          align-self: start !important;
          grid-row-start: 7;
          grid-column-start: 1;
          visibility: visible;
          display: block;
        }
      }

      a {
        &:nth-child(6) {
          grid-row-start: 4;
          grid-column-start: 1;
        }
        &:nth-child(7) {
          grid-row-start: 5;
          grid-column-start: 1;
        }
        &:nth-child(8) {
          grid-row-start: 6;
          grid-column-start: 1;
        }
      }
    }

    .menu-slide {
      padding-left: unset;
      padding: 0px;
      padding-top: 0%;
      padding-bottom: 20vh;
      height: 80vh;

      .slide {
        ul {
          padding-left: 12px;
          li {
            width: 100%;
            a {
              padding-left: 12px;
            }
          }
        }
      }
    }

    .menu-search {
      margin-top: 0%;
      z-index: 2;
      div {
        width: 100vw;
        height: 60vh;
        z-index: 1;

        form {
          .content {
            .input {
              width: 200px !important;
            }

            button {
              align-self: end;
              margin-left: 24px;
            }
          }
        }
      }
    }
  }
}
//      Mobile Styles Ends Here
