.l-contact-canvas {
  background-color: $color-secondary-light;
  min-height: 85vh;
  padding-top: 15vh;
  padding-bottom: 400px;
  color: #d28e77;

  h1 {
    align-self: center;
    text-align: center;
    grid-row-start: 1;
    text-transform: capitalize;
    color: $color-primary-dark;
    animation: arriveRight 3s ease-in-out;
  }

  .l-contact {
    display: grid;
    grid-template-columns: auto 80rem 20rem auto;

    iframe {
      width: 80rem !important;
      height: 40rem !important;
      grid-column-start: 2;
    }

    &__address {
      grid-column-start: 3;
      align-self: end;
      padding: 2rem;
      font-size: $font-small;
      color: inherit;
    }
  }

  .l-contact-links {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 90rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5rem;

    .main--social {
      justify-self: center;
      h3 {
        text-align: center;
      }

      .social--icons {
        margin-top: 2rem;

        a {
          margin: 2rem;
        }
      }
    }

    .email {
      justify-self: center;
      font-size: $font-small;
    }
  }
}

//      Mobile Styles Starts Here
@media (max-width: 765px) and (min-width: 300px) {
  .l-contact-canvas {
    padding-top: 30vh;
    padding-top: 30vh;
    .title {
      font-size: 5rem;
    }

    .l-contact {
      display: grid;
      grid-template-rows: auto 80rem 20rem auto;

      iframe {
        width: 90vw !important;
        height: 40rem !important;
        grid-row-start: 1;
      }
    }
  }
}
//      Mobile Styles Ends Here
